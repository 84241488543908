/**
 * transfer to constants/pages/contentful.js
 */

export const TEMPLATES = ['Regular', 'Article', 'ArticleWithSidebar', 'ArticleWithSidebarV2', 'ArticleLight', 'ArticleLightWithSidebar', 'Article3Column']

export const TEMPLATE = {
  Regular: {
    contentfulName: 'Regular',
    name: 'TemplatesContent'
  },
  Article: {
    contentfulName: 'Article',
    name: 'TemplatesRegular'
  },
  ArticleWithSidebar: {
    contentfulName: 'ArticleWithSidebar',
    name: 'TemplatesArticleWithSidebar'
  },
  ArticleWithSidebarV2: {
    contentfulName: 'ArticleWithSidebarV2',
    name: 'TemplatesContentWithSidebarV2'
  },
  ArticleLight: {
    contentfulName: 'ArticleLight',
    name: 'TemplatesArticleLight'
  },
  ArticleLightWithSidebar: {
    contentfulName: 'ArticleLightWithSidebar',
    name: 'TemplatesArticleLightWithSidebar'
  }
}

// TODO: Remove all GSD_THEME constants
export const GSD_THEME = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  AB_SPLIT: 'ab'
}

export const PAGE_TYPES = {
  Articles: 'article',
  Guides: 'guide',
  Profiles: 'profile'
}

export const INLINE_COMPONENTS = {
  CTA: 'AtomsCta',
  KeyPoints: 'MoleculesKeypoints',
  InlineAge: 'MoleculesInlineAge',
  InlineCardCta: 'MoleculesInlineCardCta',
  InlineLifeStage: 'MoleculesInlineLifeStage',
  InlinePartner: 'MoleculesInlinePartner',
  VerticalFormWidget: 'MoleculesVerticalFormWidget',
  AUTHORBLOCK: 'AtomsAuthorBlock'
}

export const EXPERT_EASE_LOAD_MORE_PER_PAGE = 8
export const EXPERT_EASE_AUTHOR_URL = '/expert-analysis/author'
export const EXPERT_ANALYSIS_IMAGE_MAX_WIDTH = 2500
export const EXPERT_ANALYSIS_IMAGE_MAX_HEIGHT = 2500

export const EXPERT_ANALYSIS_COMPONENT_TEXT_BOX = 'TextBox'

export const PERKS_LOAD_MORE_PER_PAGE = 8
export const OFFERS_LOAD_MORE_PER_PAGE = 8

export const MEDIA_LOAD_MORE_PER_PAGE = 8
export const MEDIA_AUTHOR_URL = '/media/author'
